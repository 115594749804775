import {
  DashboardOutlined,
  UserOutlined,
  BarChartOutlined,
  ProjectOutlined,
  CrownOutlined,
  SolutionOutlined,
  PlayCircleOutlined,
  AudioOutlined,
  FileDoneOutlined,
  FileImageOutlined,
  PicRightOutlined,
  CodeOutlined,
  PicCenterOutlined,
  FundProjectionScreenOutlined,
  LineChartOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";
import {
  APP_PREFIX_PATH
} from "configs/AppConfig";
import { getAdminStatus, getVendorStatus } from "utils/yugoUtils";

const adminStatus = getAdminStatus();
const isVendorLogIn = getVendorStatus();

const dashBoardNavTranscription = [
  // {
  //   key: "home",
  //   path: `${APP_PREFIX_PATH}/transcription/dashboard`,
  //   title: "Dashboard",
  //   icon: DashboardOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "admin",
  //   path: `${APP_PREFIX_PATH}/transcription/admin`,
  //   title: "Admins",
  //   icon: CrownOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "projectrequest",
  //   path: `${APP_PREFIX_PATH}/transcription/admin/projectrequest`,
  //   title: "Project Request",
  //   icon: SolutionOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "project",
  //   path: `${APP_PREFIX_PATH}/transcription/project`,
  //   title: "Projects",
  //   icon: ProjectOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "user",
  //   path: `${APP_PREFIX_PATH}/transcription/user`,
  //   title: "Users",
  //   icon: UserOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "vendor",
  //   path: `${APP_PREFIX_PATH}/transcription/vendor`,
  //   title: "Vendor",
  //   icon: TeamOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "reports",
  //   path: `${APP_PREFIX_PATH}/transcription/reports`,
  //   title: "Project Report",
  //   icon: BarChartOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  {
    key: "labels",
    path: `${APP_PREFIX_PATH}/transcription/labels`,
    title: "Labels List",
    icon: PicRightOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "fillerword",
        path: `${APP_PREFIX_PATH}/transcription/filler_word`,
        title: "Fiiler Word ",
        icon: PicRightOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "tags",
        path: `${APP_PREFIX_PATH}/transcription/tags`,
        title: "Closed Tags",
        icon: CodeOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "label",
        path: `${APP_PREFIX_PATH}/transcription/label`,
        title: "Label",
        icon: PicCenterOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "tags2",
        path: `${APP_PREFIX_PATH}/transcription/tags_two`,
        title: "Open Tags",
        icon: CodeOutlined,
        breadcrumb: false,
        submenu: [],
      }
    ],
  },
];

const dashBoardNavTranscriptionUser = [
  // {
  //   key: "home",
  //   path: `${APP_PREFIX_PATH}/transcription/dashboard`,
  //   title: "Dashboard",
  //   icon: DashboardOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  {
    key: "transcription_project",
    path: `${APP_PREFIX_PATH}/transcription/project`,
    title: "Transcription Projects",
    icon: ProjectOutlined,
    breadcrumb: false,
    submenu: [],
  },
  // {
  //   key: "user",
  //   path: `${APP_PREFIX_PATH}/transcription/user`,
  //   title: "Users",
  //   icon: UserOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "reports",
  //   path: `${APP_PREFIX_PATH}/transcription/reports`,
  //   title: "Project Report",
  //   icon: BarChartOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "vendor",
  //   path: `${APP_PREFIX_PATH}/transcription/vendor`,
  //   title: "Vendor",
  //   icon: TeamOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // }
];

const dashBoardNavTree = [
  {
    key: "dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: "Home",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboard",
        path: `${APP_PREFIX_PATH}/dashboard`,
        title: "Dashboard",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "admin",
        path: `${APP_PREFIX_PATH}/admin`,
        title: "Admins",
        icon: CrownOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "live_project",
    path: `${APP_PREFIX_PATH}/prompt-project`,
    title: "Live Projects",
    icon: ProjectOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "project_prompt",
        path: `${APP_PREFIX_PATH}/prompt-project?type=1`,
        title: "Prompt Projects",
        icon: AudioOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "project_conversation",
        path: `${APP_PREFIX_PATH}/conversation-project?type=2`,
        title: "Conversation Projects",
        icon: PlayCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "project_translation",
        path: `${APP_PREFIX_PATH}/translation-project?type=3`,
        title: "Translation Projects",
        icon: FileDoneOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "project_annotation",
        path: `${APP_PREFIX_PATH}/annotation-project?type=4`,
        title: "Annotation Projects",
        icon: FileImageOutlined,
        breadcrumb: false,
        submenu: [],
      },
      ...dashBoardNavTranscriptionUser,
      {
        key: "projectrequest",
        path: `${APP_PREFIX_PATH}/admin/projectrequest`,
        title: "Project Request",
        icon: SolutionOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "reports",
        path: `${APP_PREFIX_PATH}/reports`,
        title: "Project Metadata",
        icon: FundProjectionScreenOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "reports",
            path: `${APP_PREFIX_PATH}/reports`,
            title: "Yugo Metadata",
            icon: BarChartOutlined,
            breadcrumb: false,
            submenu: [],
          },
          // {
          //   key: "transcription_reports",
          //   path: `${APP_PREFIX_PATH}/transcription/reports`,
          //   title: "Transcription Metadata",
          //   icon: LineChartOutlined,
          //   breadcrumb: false,
          //   submenu: [],
          // },
        ],
      },
    ],
  },
  {
    key: "user_list",
    path: `${APP_PREFIX_PATH}/user_list`,
    title: "Users List",
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "user",
        path: `${APP_PREFIX_PATH}/user`,
        title: "Users",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  ...dashBoardNavTranscription,
];

const dashBoardNavTreeUser = [
  {
    key: "dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: "Dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboard",
        path: `${APP_PREFIX_PATH}/dashboard`,
        title: "Dashboard",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      }
    ],
  },
  {
    key: "project",
    path: `${APP_PREFIX_PATH}/projects`,
    title: "Live Projects",
    icon: ProjectOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "project",
        path: `${APP_PREFIX_PATH}/prompt-project?type=1`,
        title: "Prompt Projects",
        icon: AudioOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "project_conversation",
        path: `${APP_PREFIX_PATH}/conversation-project?type=2`,
        title: "Conversation Projects",
        icon: PlayCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "project_translation",
        path: `${APP_PREFIX_PATH}/translation-project?type=3`,
        title: "Translation Projects",
        icon: FileDoneOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "project_annotation",
        path: `${APP_PREFIX_PATH}/annotation-project?type=4`,
        title: "Annotation Projects",
        icon: FileImageOutlined,
        breadcrumb: false,
        submenu: [],
      },
      ...dashBoardNavTranscriptionUser,
    ],
  },
  {
    key: "user",
    path: `${APP_PREFIX_PATH}/user`,
    title: "Users",
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "user",
        path: `${APP_PREFIX_PATH}/user`,
        title: "Users",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "query",
        path: `${APP_PREFIX_PATH}/query`,
        title: "TR Query",
        icon: QuestionCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "reports",
    path: `${APP_PREFIX_PATH}/reports`,
    title: "Project Metadata",
    icon: FundProjectionScreenOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "reports",
        path: `${APP_PREFIX_PATH}/reports`,
        title: "Yugo Metadata",
        icon: BarChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: "transcription_reports",
      //   path: `${APP_PREFIX_PATH}/transcription/reports`,
      //   title: "Transcription Metadata",
      //   icon: LineChartOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
    ],
  },
];

const dashBoardNavVendor = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/vendor/dashboard`,
    title: "Dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  // {
  //   key: "edit",
  //   path: `${APP_PREFIX_PATH}/vendor/edit_vendor`,
  //   title: "Edit Vendor",
  //   icon: EditOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // }

];

// const HomeDashboard = [
//   {
//     key: "home",
//     path: `/home/dashboard`,
//     title: "Home",
//     icon: DashboardOutlined,
//     breadcrumb: false,
//     submenu: [],
//   },
// ];

let NavigationConfig;
if (isVendorLogIn === "login_vendor") {
  NavigationConfig = [...dashBoardNavVendor];
}

NavigationConfig = adminStatus === "true" ? [...dashBoardNavTree] : [...dashBoardNavTreeUser];

// NavigationConfig = adminStatus === true ? [...dashBoardNavTranscription] : [...dashBoardNavTranscriptionUser];

export default NavigationConfig;