import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { getAdminStatus } from "utils/yugoUtils";

export const AppViews = () => {
  const adminStatus = getAdminStatus();
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route exact path={`${APP_PREFIX_PATH}/dashboard`} component={lazy(() => import(`./yugo/dashboard`))} />

        {adminStatus === "true" && <Route exact path={`${APP_PREFIX_PATH}/admin`} component={lazy(() => import(`./yugo/admin`))} />}
        {adminStatus === "true" && <Route exact path={`${APP_PREFIX_PATH}/admin/addadmin`} component={lazy(() => import(`./yugo/admin/CreateNewAdmin`))} />}
        {adminStatus === "true" && <Route exact path={`${APP_PREFIX_PATH}/admin/editadmin`} component={lazy(() => import(`./yugo/admin/EditAdmin`))} />}
        {adminStatus === "true" && <Route exact path={`${APP_PREFIX_PATH}/admin/projectrequest`} component={lazy(() => import(`./yugo/project_request`))} />}

        <Route exact path={`${APP_PREFIX_PATH}/prompt-project`} component={lazy(() => import(`./yugo/project?type=1`))} />
        <Route exact path={`${APP_PREFIX_PATH}/conversation-project`} component={lazy(() => import(`./yugo/project?type=2`))} />
        <Route exact path={`${APP_PREFIX_PATH}/translation-project`} component={lazy(() => import(`./yugo/project?type=3`))} />
        <Route exact path={`${APP_PREFIX_PATH}/annotation-project`} component={lazy(() => import(`./yugo/project?type=4`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/view/:projectdetails`} component={lazy(() => import(`./yugo/project/ViewProject`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/createproject/`} component={lazy(() => import(`./yugo/project/CreateProject`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/createuser/`} component={lazy(() => import(`./yugo/project/CreateUser`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/translation/createuser/`} component={lazy(() => import(`./yugo/project/translation/CreateUser`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/annotation/createuser/`} component={lazy(() => import(`./yugo/project/annotation/CreateUser`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/status/`} component={lazy(() => import(`./yugo/project/ProjectStatus`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/translation/status/`} component={lazy(() => import(`./yugo/project/translation/ProjectStatus`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/annotation/status/`} component={lazy(() => import(`./yugo/project/annotation/ProjectStatus`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/qa/`} component={lazy(() => import(`./yugo/project/AssignQA`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/translation/qa/`} component={lazy(() => import(`./yugo/project/translation/AssignQA`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/annotation/qa/`} component={lazy(() => import(`./yugo/project/annotation/AssignQA`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/external_qa/`} component={lazy(() => import(`./yugo/project/AssignEQA`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/cqa/`} component={lazy(() => import(`./yugo/project/ReAssignCQA`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/editproject/:id`} component={lazy(() => import(`./yugo/project/EditProject`))} />

        <Route exact path={`${APP_PREFIX_PATH}/user`} component={lazy(() => import(`./yugo/user`))} />
        <Route exact path={`${APP_PREFIX_PATH}/query`} component={lazy(() => import('./transcription/query'))} />
        <Route exact path={`${APP_PREFIX_PATH}/user/edituser`} component={lazy(() => import(`./yugo/user/EditUser`))} />
        <Route exact path={`${APP_PREFIX_PATH}/reports`} component={lazy(() => import(`./yugo/reports`))} />
        <Route exact path={`${APP_PREFIX_PATH}/data-not-found`} component={lazy(() => import(`./yugo/DataNotFound/index`))} />

        {/* <Route exact path={`${APP_PREFIX_PATH}/transcription/dashboard`} component={lazy(() => import(`./transcription/dashboard/index`))} /> */}
        {adminStatus === "true" && <Route exact path={`${APP_PREFIX_PATH}/transcription/admin`} component={lazy(() => import(`./transcription/admin`))} />}
        {adminStatus === "true" && <Route exact path={`${APP_PREFIX_PATH}/transcription/admin/addadmin`} component={lazy(() => import(`./transcription/admin/CreateNewAdmin`))} />}
        {adminStatus === "true" && <Route exact path={`${APP_PREFIX_PATH}/transcription/admin/editadmin`} component={lazy(() => import(`./transcription/admin/EditAdmin`))} />}
        {adminStatus === "true" && <Route exact path={`${APP_PREFIX_PATH}/transcription/admin/projectrequest`} component={lazy(() => import(`./transcription/project_request`))} />}
        <Route exact path={`${APP_PREFIX_PATH}/transcription/project`} component={lazy(() => import(`./transcription/project`))} />
        <Route exact path={`${APP_PREFIX_PATH}/transcription/project/view/:projectdetails`} component={lazy(() => import(`./transcription/project/ViewProject`))} />
        <Route exact path={`${APP_PREFIX_PATH}/transcription/project/createproject/`} component={lazy(() => import(`./transcription/project/CreateProject`))} />
        <Route exact path={`${APP_PREFIX_PATH}/transcription/project/uploadfiles/`} component={lazy(() => import(`./transcription/project/UploadFiles`))} />
        <Route exact path={`${APP_PREFIX_PATH}/transcription/project/createuser/`} component={lazy(() => import(`./transcription/project/CreateUser`))} />
        <Route exact path={`${APP_PREFIX_PATH}/transcription/project/assignfilestoqa/`} component={lazy(() => import(`./transcription/project/AssignFilesToQA`))} />
        <Route exact path={`${APP_PREFIX_PATH}/transcription/project/status/`} component={lazy(() => import(`./transcription/project/ProjectStatus`))} />
        <Route exact path={`${APP_PREFIX_PATH}/transcription/project/queries/:id`} component={lazy(() => import(`./transcription/project/Query`))} />
        <Route exact path={`${APP_PREFIX_PATH}/transcription/project/editproject/:id`} component={lazy(() => import(`./transcription/project/EditProject`))} />
        <Route exact path={`${APP_PREFIX_PATH}/transcription/user`} component={lazy(() => import(`./transcription/user`))} />
        <Route exact path={`${APP_PREFIX_PATH}/transcription/user/edituser`} component={lazy(() => import(`./transcription/user/EditUser`))} />
        <Route exact path={`${APP_PREFIX_PATH}/transcription/reports`} component={lazy(() => import(`./transcription/reports`))} />



        {/*  TRANSLATIONS */}

        {/* Vendor Routes */}
        {/* <Route exact path={`${APP_PREFIX_PATH}/transcription/vendor/`} component={lazy(() => import(`./transcription/createVendor`))} />
        <Route exact path={`${APP_PREFIX_PATH}/transcription/vendor/add_vendor`} component={lazy(() => import(`./transcription/createVendor/CreateNewVendor`))} />
        <Route exact path={`${APP_PREFIX_PATH}/transcription/vendor/edit_vendor`} component={lazy(() => import(`./transcription/createVendor/EditVendor`))} />
        <Route exact path={`${APP_PREFIX_PATH}/transcription/vendor/create_vendor_user`} component={lazy(() => import(`./transcription/createVendor/CreateVendorUser`))} /> */}

        {/*  Transcription Labels Add Routes */}
        {adminStatus === "true" && <Route exact path={`${APP_PREFIX_PATH}/transcription/filler_word`} component={lazy(() => import(`./transcription/filler-word`))} />}
        {adminStatus === "true" && <Route exact path={`${APP_PREFIX_PATH}/transcription/tags`} component={lazy(() => import(`./transcription/tag`))} />}
        {adminStatus === "true" && <Route exact path={`${APP_PREFIX_PATH}/transcription/label`} component={lazy(() => import(`./transcription/label`))} />}
        {adminStatus === "true" && <Route exact path={`${APP_PREFIX_PATH}/transcription/tags_two`} component={lazy(() => import(`./transcription/tag2`))} />}

        <Route exact path={`${APP_PREFIX_PATH}/transcription/data-not-found`} component={lazy(() => import(`./transcription/DataNotFound/index`))} />

        {/* <Route exact path={`${APP_PREFIX_PATH}/vendor/dashboard`} component={lazy(() => import(`./vendor/project`))} />
        <Route exact path={`${APP_PREFIX_PATH}/vendor/edit_vendor`} component={lazy(() => import(`./vendor/EditVendor`))} />
        <Route exact path={`${APP_PREFIX_PATH}/vendor/project`} component={lazy(() => import(`./vendor/project`))} />
        <Route exact path={`${APP_PREFIX_PATH}/vendor/project/view/:projectdetails`} component={lazy(() => import(`./vendor/project/ViewProject`))} /> */}


        {/* <Route exact path={`${APP_PREFIX_PATH}/vendor/project/createproject/`} component={lazy(() => import(`./vendor/project/CreateProject`))} /> */}


        {/* <Route exact path={`${APP_PREFIX_PATH}/vendor/project/createuser/`} component={lazy(() => import(`./vendor/project/CreateUser`))} />
        <Route exact path={`${APP_PREFIX_PATH}/vendor/project/status/`} component={lazy(() => import(`./vendor/project/ProjectStatus`))} /> */}


        {/* <Route exact path={`${APP_PREFIX_PATH}/vendor/project/qa/`} component={lazy(() => import(`./vendor/project/AssignQA`))} /> */}
        {/* <Route exact path={`${APP_PREFIX_PATH}/vendor/project/editproject/:id`} component={lazy(() => import(`./vendor/project/EditProject`))} /> */}

        {/* <Route exact path={`${APP_PREFIX_PATH}/vendor/data-not-found`} component={lazy(() => import(`./vendor/DataNotFound/index`))} /> */}

        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboard`} />

      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
